import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React from "react"

interface IHero {
  image: string;
  content?: any; // Markdown
}

const Hero = ({data, children}: {data: any, children: any}) => {  
  return (
    <section className="container mx-auto md:grid grid-cols-12 gap-6">
      {/* Content */}
      <div className="col-start-1 row-start-1 col-span-9">

        {/* If used in HTML template, children are provided */}
        {children && <div className="grid grid-cols-9 gap-x-6 gap-y-8 py-16 lg:pt-24 px-6 md:px-0
                        bg-brand-blue bg-fill-left before:bg-brand-blue
                        left-line text-white">
          {children}   
        </div>}

        {/* If used in page builder, markdown content is provided */}
        {data.content && <div className="hero-content grid grid-cols-9 gap-x-6 gap-y-8 py-16 lg:pt-24 px-6 md:px-0
                        bg-brand-blue bg-fill-left before:bg-brand-blue
                        left-line text-white" 
            dangerouslySetInnerHTML={{
              __html: data.content.data.childMarkdownRemark.html,
            }}>
        </div>}
      </div>

      {/* Image */}
      <div className="col-start-8 row-start-1 col-span-5 py-12 md:py-16 px-6 md:px-0">
        <div className="img-deco-right">
          {/* If used in HTML template, image is provided */}
          <img src={data.image} alt="" />

          {/* <GatsbyImage image={} /> */}
          
          {/* If used in page builder, GatsbyImage is provided */}
          {data.image && typeof data.image !== 'string' && <GatsbyImage
            image={getImage(data.image.localFile)}
            alt={data.image.alternativeText}
          />}

        </div>
      </div>
    </section>
  )
}

export default Hero